//Fully Working Code with Download Students working V2

import React, { useState, useEffect } from "react";
import { Button, Box, Typography } from "@mui/material";
import NewRegistration from "./NewRegistration";
import StudentSearch from "./StudentSearch";
import BulkRegistration from "./BulkRegistration";

const RegistrationForm = ({ reset }) => {
  const [view, setView] = useState("home");

  useEffect(() => {
    if (reset) {
      setView("home");
    }
  }, [reset]);

  const handleButtonClick = (view) => {
    setView(view);
  };

  const handleBack = () => {
    setView("home");
  };

  const handleDownload = () => {
    // URL of your PHP script
    window.location.href = 'https://backend.navituschemicals.in/export_csv1.php';
  };

  return (
    <Box p={3}>
      {view === "home" && (
        <>
          <Typography variant="h4" align="center" style={{ fontSize: 36 }}>
            Student Management
          </Typography>
          <Box mt={4} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleButtonClick("search")}
              style={{ margin: "10px" }}
            >
              Student Search
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleButtonClick("registration")}
              style={{ margin: "10px" }}
            >
              New Registration
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleButtonClick("bulk")}
              style={{ margin: "10px" }}
            >
              Bulk Registration
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDownload}
              style={{ margin: "10px" }}
            >
              Download Students
            </Button>
          </Box>
        </>
      )}
      {view === "registration" && <NewRegistration onBack={handleBack} />}
      {view === "search" && (
        <StudentSearch onBack={() => handleButtonClick("home")} />
      )}
      {view === "bulk" && (
        <BulkRegistration onBack={() => handleButtonClick("home")} />
      )}
      {/* Add similar conditional rendering for other views (search, bulk, download) if needed */}
    </Box>
  );
};

export default RegistrationForm;
