// import React, { useState } from "react";
// import {
//   TextField, Button, Box, Typography, Grid, IconButton, Select, MenuItem, FormControl, InputLabel, FormHelperText
// } from "@mui/material";
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import CustomDatePicker from './CustomDatePicker'; // Ensure correct import path
// import { format, addMonths } from 'date-fns'; // Import date-fns functions

// const NewRegistration = ({ onBack }) => {
//   const initialFormData = {
//     name: '',
//     fathersName: '',
//     courseCode: '',
//     dateOfBirth: '',
//     centerCode: '',
//     sessionStarting: null,
//     sessionEnding: null
//   };

//   const [formData, setFormData] = useState(initialFormData);
//   const [errors, setErrors] = useState({});

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     if (name === "courseCode") {
//       const newSessionEnding = calculateSessionEnding(formData.sessionStarting, value);
//       setFormData({
//         ...formData,
//         [name]: value,
//         sessionEnding: newSessionEnding
//       });
//     } else {
//       setFormData({ ...formData, [name]: value });
//     }
//   };

//   const handleDateChange = (name, date) => {
//     const isoDate = date.toISOString(); // Ensure date is in ISO format
//     const newFormData = { ...formData, [name]: isoDate };
//     if (name === "sessionStarting" && formData.courseCode) {
//         const newSessionEnding = calculateSessionEnding(date, formData.courseCode);
//         newFormData.sessionEnding = newSessionEnding;
//     }
//     setFormData(newFormData);
// };

//   const calculateSessionEnding = (startDate, courseCode) => {
//     if (!startDate) return null;
//     const durationInMonths = {
//       'DFSE': 11,
//       'CFSE': 5,
//       'DIS': 11,
//       'ADISE': 11,
//       'ADFISE': 11,
//       'DFISE': 11
//     };
//     const monthsToAdd = durationInMonths[courseCode] || 0;
//     const endDate = addMonths(new Date(startDate), monthsToAdd);
//     return endDate; // Keep the date object format
//   };

//   const formatDate = (date) => {
//     if (!date) return '';
//     return format(new Date(date), 'MMMM yyyy'); // Format as "Month Year"
//   };

//   const resetForm = () => {
//     setFormData(initialFormData);
//     setErrors({});
//   };

//   const validateForm = () => {
//     const errors = {};
//     if (!formData.name) errors.name = 'Name is required';
//     if (!formData.fathersName) errors.fathersName = 'Father\'s Name is required';
//     if (!formData.dateOfBirth) errors.dateOfBirth = 'Date of Birth is required';
//     if (!formData.courseCode) errors.courseCode = 'Course Code is required';
//     if (!formData.sessionStarting) errors.sessionStarting = 'Session Starting is required';
//     if (!formData.sessionEnding) errors.sessionEnding = 'Session Ending is required';
//     if (!formData.centerCode) errors.centerCode = 'Center Code is required';

//     // Check if sessionStarting is in July or January
//     if (formData.sessionStarting) {
//       const startDate = new Date(formData.sessionStarting);
//       const month = startDate.getMonth(); // 0 for January, 6 for July
//       if (month !== 0 && month !== 6) {
//         errors.sessionStarting = 'Session Starting Month should be July or January';
//       }
//     }

//     return errors;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const errors = validateForm();
//     setErrors(errors);

//     if (Object.keys(errors).length > 0) return;

//     const calculateAge = (dob) => {
//       const today = new Date();
//       const birthDate = new Date(dob);
//       let age = today.getFullYear() - birthDate.getFullYear();
//       const monthDifference = today.getMonth() - birthDate.getMonth();
//       if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
//         age--;
//       }
//       return age;
//     };

//     const age = calculateAge(formData.dateOfBirth);
//     if (age < 18) {
//       alert('You must be at least 18 years old to register.');
//       return;
//     }

//     try {
//       const response = await fetch('http://localhost/test/src/components/Backend/script.php', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(formData),
//       });
//       const result = await response.json();
//       console.log('Response:', result);
//       if (result.success) {
//         alert('Registration successful!');
//         resetForm();
//       } else {
//         alert('Registration failed.');
//       }
//     } catch (error) {
//       console.error('Error:', error);
//       alert('An error occurred.');
//     }
//   };

//   return (
//     <Box p={3}>
//       <Box display="flex" alignItems="center">
//         <IconButton edge="start" color="inherit" onClick={onBack}>
//           <ArrowBackIcon />
//         </IconButton>
//         <Typography variant="h4" align="center" style={{ fontSize: 36, flexGrow: 1 }}>
//           Student Registration
//         </Typography>
//       </Box>
//       <form noValidate autoComplete="off" onSubmit={handleSubmit}>
//         <Grid container spacing={2}>
//           <Grid item xs={12} sm={6}>
//             <TextField
//               fullWidth
//               margin="normal"
//               label="Full Name"
//               variant="outlined"
//               name="name"
//               value={formData.name}
//               onChange={handleChange}
//               error={!!errors.name}
//               helperText={errors.name}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <TextField
//               fullWidth
//               margin="normal"
//               label="Father's Name"
//               variant="outlined"
//               name="fathersName"
//               value={formData.fathersName}
//               onChange={handleChange}
//               error={!!errors.fathersName}
//               helperText={errors.fathersName}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <TextField
//               fullWidth
//               margin="normal"
//               label="Date of Birth"
//               type="date"
//               InputLabelProps={{ shrink: true }}
//               variant="outlined"
//               name="dateOfBirth"
//               value={formData.dateOfBirth}
//               onChange={handleChange}
//               error={!!errors.dateOfBirth}
//               helperText={errors.dateOfBirth}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <FormControl fullWidth margin="normal" variant="outlined" error={!!errors.courseCode}>
//               <InputLabel id="course-code-label">Course</InputLabel>
//               <Select
//                 labelId="course-code-label"
//                 name="courseCode"
//                 value={formData.courseCode}
//                 onChange={handleChange}
//                 label="Course"
//               >
//                 <MenuItem value="DFSE">DFSE</MenuItem>
//                 <MenuItem value="CFSE">CFSE</MenuItem>
//                 <MenuItem value="DIS">DIS</MenuItem>
//                 <MenuItem value="ADISE">ADISE</MenuItem>
//                 <MenuItem value="ADFISE">ADFISE</MenuItem>
//                 <MenuItem value="DFISE">DFISE</MenuItem>
//               </Select>
//               {errors.courseCode && <FormHelperText>{errors.courseCode}</FormHelperText>}
//             </FormControl>
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <Box>
//               <Typography variant="subtitle1">Session Starting</Typography>
//               <CustomDatePicker
//                 selected={formData.sessionStarting}
//                 onChange={(date) => handleDateChange('sessionStarting', date)}
//                 placeholderText="Select session starting"
                
//               />
//               {errors.sessionStarting && (
//       <FormHelperText error>{errors.sessionStarting}</FormHelperText>
//               )}
//             </Box>
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <Box>
//               <Typography variant="subtitle1">Session Ending</Typography>
//               <TextField
//                 fullWidth
//                 variant="outlined"
//                 value={formatDate(formData.sessionEnding)}
//                 InputProps={{
//                   readOnly: true,
//                 }}
//                 InputLabelProps={{ shrink: true }}
//                 margin="normal"
//                 sx={{ minHeight: 56, mt: "1px" }} // Adjust top margin to decrease space
//                 //style={{ minHeight: '56px' }} // Match height with other fields
//               />
//             </Box>
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <TextField
//               fullWidth
//               margin="normal"
//               label="Center Code"
//               variant="outlined"
//               name="centerCode"
//               value={formData.centerCode}
//               onChange={handleChange}
//               error={!!errors.centerCode}
//               helperText={errors.centerCode}
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <Button type="submit" variant="contained" color="primary">
//               Submit
//             </Button>
//             <Button variant="outlined" onClick={resetForm} style={{ marginLeft: 8 }}>
//               Cancel
//             </Button>
//           </Grid>
//         </Grid>
//       </form>
//     </Box>
//   );
// };

// export default NewRegistration;


import React, { useState } from "react";
import {
  TextField, Button, Box, Typography, Grid, IconButton, Select, MenuItem, FormControl, InputLabel, FormHelperText
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CustomDatePicker from './CustomDatePicker'; // Ensure correct import path
import { format, addMonths } from 'date-fns'; // Import date-fns functions

const NewRegistration = ({ onBack }) => {
  const initialFormData = {
    name: '',
    fathersName: '',
    courseCode: '',
    dateOfBirth: '',
    centerCode: '',
    sessionStarting: null,
    sessionEnding: null,
    serialNo: '', // Add SerialNo to form data
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "courseCode") {
      const newSessionEnding = calculateSessionEnding(formData.sessionStarting, value);
      setFormData({
        ...formData,
        [name]: value,
        sessionEnding: newSessionEnding
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleDateChange = (name, date) => {
    const isoDate = date.toISOString(); // Ensure date is in ISO format
    const newFormData = { ...formData, [name]: isoDate };
    if (name === "sessionStarting" && formData.courseCode) {
        const newSessionEnding = calculateSessionEnding(date, formData.courseCode);
        newFormData.sessionEnding = newSessionEnding;
    }
    setFormData(newFormData);
};

  const calculateSessionEnding = (startDate, courseCode) => {
    if (!startDate) return null;
    const durationInMonths = {
      'DFSE': 11,
      'CFSE': 5,
      'DIS': 11,
      'ADISE': 11,
      'ADFISE': 11,
      'DFISE': 11
    };
    const monthsToAdd = durationInMonths[courseCode] || 0;
    const endDate = addMonths(new Date(startDate), monthsToAdd);
    return endDate; // Keep the date object format
  };

  const formatDate = (date) => {
    if (!date) return '';
    return format(new Date(date), 'MMMM yyyy'); // Format as "Month Year"
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setErrors({});
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = 'Name is required';
    if (!formData.fathersName) errors.fathersName = 'Father\'s Name is required';
    if (!formData.dateOfBirth) errors.dateOfBirth = 'Date of Birth is required';
    if (!formData.courseCode) errors.courseCode = 'Course Code is required';
    if (!formData.sessionStarting) errors.sessionStarting = 'Session Starting is required';
    if (!formData.sessionEnding) errors.sessionEnding = 'Session Ending is required';
    if (!formData.centerCode) errors.centerCode = 'Center Code is required';
    if (!formData.serialNo) errors.serialNo = 'Serial No. is required'; // Validate Serial No.

    // Check if sessionStarting is in July or January
    if (formData.sessionStarting) {
      const startDate = new Date(formData.sessionStarting);
      const month = startDate.getMonth(); // 0 for January, 6 for July
      if (month !== 0 && month !== 6) {
        errors.sessionStarting = 'Session Starting Month should be July or January';
      }
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm();
    setErrors(errors);

    if (Object.keys(errors).length > 0) return;

    const calculateAge = (dob) => {
      const today = new Date();
      const birthDate = new Date(dob);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();
      if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    };

    const age = calculateAge(formData.dateOfBirth);
    if (age < 18) {
      alert('You must be at least 18 years old to register.');
      return;
    }

    try {
      const response = await fetch('https://backend.navituschemicals.in/script1.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
      console.log('Response:', result);
      if (result.success) {
        alert('Registration successful!');
        resetForm();
      } else {
        alert('Registration failed.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred.');
    }
  };

  return (
    <Box p={3}>
      <Box display="flex" alignItems="center">
        <IconButton edge="start" color="inherit" onClick={onBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" align="center" style={{ fontSize: 36, flexGrow: 1 }}>
          Student Registration
        </Typography>
      </Box>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              label="Serial No."
              variant="outlined"
              name="serialNo"
              value={formData.serialNo}
              onChange={handleChange}
              error={!!errors.serialNo}
              helperText={errors.serialNo}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              label="Full Name"
              variant="outlined"
              name="name"
              value={formData.name}
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              label="Father's Name"
              variant="outlined"
              name="fathersName"
              value={formData.fathersName}
              onChange={handleChange}
              error={!!errors.fathersName}
              helperText={errors.fathersName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              label="Date of Birth"
              type="date"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleChange}
              error={!!errors.dateOfBirth}
              helperText={errors.dateOfBirth}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal" variant="outlined" error={!!errors.courseCode}>
              <InputLabel id="course-code-label">Course</InputLabel>
              <Select
                labelId="course-code-label"
                name="courseCode"
                value={formData.courseCode}
                onChange={handleChange}
                label="Course"
              >
                <MenuItem value="DFSE">DFSE</MenuItem>
                <MenuItem value="CFSE">CFSE</MenuItem>
                <MenuItem value="DIS">DIS</MenuItem>
                <MenuItem value="ADISE">ADISE</MenuItem>
                <MenuItem value="ADFISE">ADFISE</MenuItem>
                <MenuItem value="DFISE">DFISE</MenuItem>
              </Select>
              {errors.courseCode && <FormHelperText>{errors.courseCode}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <Typography variant="subtitle1">Session Starting</Typography>
              <CustomDatePicker
                selected={formData.sessionStarting}
                onChange={(date) => handleDateChange('sessionStarting', date)}
                placeholderText="Select session starting"
              />
              {errors.sessionStarting && (
                <FormHelperText error>{errors.sessionStarting}</FormHelperText>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <Typography variant="subtitle1">Session Ending</Typography>
              <TextField
                fullWidth
                variant="outlined"
                value={formatDate(formData.sessionEnding)}
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{ shrink: true }}
                margin="normal"
                sx={{ minHeight: 56, mt: "1px" }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              label="Center Code"
              variant="outlined"
              name="centerCode"
              value={formData.centerCode}
              onChange={handleChange}
              error={!!errors.centerCode}
              helperText={errors.centerCode}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
            <Button variant="outlined" onClick={resetForm} style={{ marginLeft: 8 }}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default NewRegistration;
