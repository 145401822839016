import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Grid, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import axios from 'axios';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, Image, StyleSheet, Font } from '@react-pdf/renderer';
import certificateTemplate from '../Img/Format1.png'; // Ensure this is a valid image path
import BoldItalic from '../Fonts/OpenSans-BoldItalic.ttf';
import Bold from '../Fonts/OpenSans-Bold.ttf';

//Date Format dd-mm-yyyy
const formatDate = (dateString) => {
  if (!dateString) return '';

  const [year, month, day] = dateString.split('-');

  // Pad month and day to ensure they are always two digits
  const formattedDay = day.padStart(2, '0');
  const formattedMonth = month.padStart(2, '0');

  return `${formattedDay}-${formattedMonth}-${year}`;
};

//ISSUED DATE CODE
const extractStartMonthYear = (session) => {
  const [startSession] = session.split(' - ');
  const [startMonthStr, startYear] = startSession.split(' ');

  // Map month names to numbers
  const monthMap = {
    January: 0,
    February: 1,
    March: 2,
    April: 3,
    May: 4,
    June: 5,
    July: 6,
    August: 7,
    September: 8,
    October: 9,
    November: 10,
    December: 11,
  };

  const startMonth = monthMap[startMonthStr];
  const startYearInt = parseInt(startYear, 10);

  return { month: startMonth, year: startYearInt };
};

//Added Suffix to Date
const getFirstMonday = (month, year) => {
  let date = new Date(year, month, 1); // Start with the 1st day of the given month and year
  const dayOfWeek = date.getDay();
  const daysUntilMonday = (dayOfWeek === 0 ? 1 : 8 - dayOfWeek) % 7;
  date.setDate(date.getDate() + daysUntilMonday);
  return date;
};

const formatIssueDate = (date) => {
  const day = date.getDate();
  const monthName = date.toLocaleString('en-US', { month: 'long' });
  const year = date.getFullYear();
  const formattedDay = formatDateWithSuffix(day);
  return `${formattedDay} ${monthName} ${year}`;
};

const formatDateWithSuffix = (day) => {
  if (day > 3 && day < 21) return day + 'th'; // Special case for 11th-13th
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const index = (day % 10 > 3) ? 0 : day % 10;
  return day + suffixes[index];
};

//BoldItalic Font
Font.register({
  family: 'BoldItalic',
  src: BoldItalic,
});
//Bold Font
Font.register({
  family: 'Bold',
  src: Bold,
});
 
const Marksheet = () => {
  const [name, setName] = useState('');
  const [enrollmentNumber, setEnrollmentNumber] = useState('');
  const [session, setSession] = useState('');
  const [courseCode, setCourseCode] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [certificateGenerated, setCertificateGenerated] = useState(false);
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [fathersName, setFathersName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [subject_name, setSubjectName] = useState('');
  //const [srNo, setSerialNo] = useState('');
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [SerialNo, setSerialNumber] = useState('');

  //ISSUED DATE
  const { month, year } = extractStartMonthYear(session);
  const issuedDate = getFirstMonday(month, year);
  const formattedIssuedDate = formatIssueDate(issuedDate);


  //Subject Name in Part1 and Part2
const part1Subjects = [];
const part2Subjects = [];

if (courseCode === 'DFSE' || courseCode === 'CFSE') {
  part1Subjects.push(...searchResults.slice(0, searchResults.length - 4)); // All but the last 4 subjects
  part2Subjects.push(...searchResults.slice(-4)); // Last 4 subjects
} else {
  part1Subjects.push(...searchResults); // All subjects if not DFSE or CFSE
}

  
  useEffect(() => {
    // Reset certificate generation state when enrollment number changes
    setCertificateGenerated(false);
    setShowDownloadButton(false);
  }, [enrollmentNumber]);

  const handleSearch = async () => {
    if (!enrollmentNumber) {
      setError('Enrollment Number is required.');
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await axios.get('https://backend.navituschemicals.in/search2.php', {
        params: { enrollmentNumber }
      });

      if (response.data.length > 0) {
        const { name, session, courseCode, fathersName, dateOfBirth, marks, srNo, SerialNo } = response.data[0];
        setName(name || '');
        setSession(session || '');
        setCourseCode(courseCode || '');
        setFathersName(fathersName || '');
        setDateOfBirth(dateOfBirth || '');
        setSubjectName(subject_name || '');
        setSearchResults(marks || []); // Set marks data
        //setSerialNo( srNo || ''); // Set marks data
        setSerialNumber( SerialNo || ''); // Set marks data
        setSearchCompleted(true);
      } else {
        setError('No data found for the provided enrollment number.');
        setSearchCompleted(false);
      }
    } catch (error) {
      console.error('Error fetching search results:', error);
      setError('Failed to fetch search results. Please try again.');
      setSearchCompleted(false);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateCertificate = () => {
    setCertificateGenerated(true);
    setShowDownloadButton(true);
  };

   // Function to calculate total marks
   const calculateTotalMarks = () => {
    return searchResults.reduce((total, mark) => total + (mark.total_marks || 0), 0);
  };

  // **New Function to Calculate Total Marks Obtained**
  const calculateTotalObtainedMarks = () => {
    return searchResults.reduce((total, mark) => total + (mark.marks_obtained || 0), 0);
  }; 

  //Function to Calculate Percentage
  const calculatePercentage = () => {
    const totalMarks = calculateTotalMarks();
    const totalObtainedMarks = calculateTotalObtainedMarks();
  
    if (totalMarks === 0) return '0%'; // Prevent division by zero
  
    const percentage = (totalObtainedMarks / totalMarks) * 100;
    return percentage.toFixed(2) + '%';
  };
  


  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#FFFFFF',
      padding: 20,
    },
    image: {
      width: 100,
      height: 100,
      borderRadius: 50,
      marginBottom: 20,
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
      marginBottom: 10,
    },
    text: {
      fontSize: 18,
      marginBottom: 5,
    },
    container: {
      marginTop: 20,
      textAlign: 'center',
    },
    certificateContainer: {
      marginTop: 20,
      border: '1px solid #ddd',
      padding: 10,
      borderRadius: 4,
    },
    downloadButton: {
      textDecoration: 'none',
      color: 'white',
      backgroundColor: '#1976d2',
      padding: '10px 20px',
      borderRadius: '4px',
      fontWeight: 'bold',
      display: 'inline-block',
      marginLeft: 16,
    },
    detailsContainer: {
      marginBottom: 20,
    },
    detailsText: {
      marginBottom: 8,
    }
  });


  // Check if "PRACTICAL" section should be displayed
   const showPractical = courseCode === 'DFSE';
   const showPractical1 =  courseCode === 'CFSE';


  const calculateSessionDuration = (session) => {
  const [start, end] = session.split(' - ');
  const [startMonth, startYear] = start.split(' ');
  const [endMonth, endYear] = end.split(' ');

  // Month mapping
  const monthMap = {
    January: 0,
    February: 1,
    March: 2,
    April: 3,
    May: 4,
    June: 5,
    July: 6,
    August: 7,
    September: 8,
    October: 9,
    November: 10,
    December: 11,
  };

  const startMonthIndex = monthMap[startMonth];
  const endMonthIndex = monthMap[endMonth];

  const yearDifference = parseInt(endYear) - parseInt(startYear);
  const monthDifference = endMonthIndex - startMonthIndex;

  // Total months
  const totalMonths = yearDifference * 12 + monthDifference;

  // Format output based on the total months
  if (totalMonths === 0) {
    return "Less than a month";
  } else if (totalMonths === 1) {
    return "One Month";
  } else if (totalMonths === 6) {  // Highlighted change for 6 months
    return "Six Months";  // Highlighted change for 6 months
  } else if (totalMonths === 12) { // Highlighted change for 12 months
    return "One Year";   // Highlighted change for 12 months
  } else if (totalMonths < 12) {
    return `${totalMonths + 1} Month${totalMonths === 0 ? '' : 's'}`;
  } else {
    return `${Math.floor(totalMonths / 12)} Year${Math.floor(totalMonths / 12) === 1 ? '' : 's'}`;
  }
};

const FormattedCalculateSessionDuration = (session) => {
  const duration = calculateSessionDuration(session);
  
  // Map the output to desired format
  if (duration === "12 Months") {
    return "One Year";
  } else if (duration === "6 Months") {
    return "Six Months";
  } else {
    return duration; // Return other durations as they are
  }
};




  const MyDocument = () => (
    <Document>
      <Page style={styles.page}>
        <View style={{ flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
          {/* Background image */}
          <Image
            src={certificateTemplate}
            style={{
              width: 560,
              height: 800,
              position: 'absolute',
              top: 0,
              left: 0
            }}
          />
          
          {/* Text elements with absolute positioning */}
          <Text style={{ position: 'absolute', top: 224, left: 170, fontSize: 10, fontWeight: '700', width:100 }}>
            {name}
          </Text>
          <Text style={{ position: 'absolute', top: 212, left: 170, fontSize: 9, width: 300 }}>
            {enrollmentNumber}
          </Text>
          <Text style={{ position: 'absolute', top: 260, left: 225, fontSize: 10, width: 300 }}>
            [{session}]
          </Text>
          <Text style={{ position: 'absolute', top: 248, left: 170, fontSize: 10, width: 300 }}>
            {courseCode}
          </Text>
          <Text style={{ position: 'absolute', top: 236, left: 170, fontSize: 10, width: 300 }}>
            {fathersName}
          </Text>
          <Text style={{ position: 'absolute', top: 563, left: 460, fontSize: 8, width: 300 }}>
          {formatDate(dateOfBirth)}
          </Text> 
          <Text style={{ position: 'absolute', top: 68, left: 120, fontSize: 8, width: 300, fontFamily:'Bold' }}>
            {SerialNo}
          </Text>
          <Text style={{ position: 'absolute', top: 545, left: 495, fontSize: 10, width: 300, fontFamily:'Bold' }}>
             A
          </Text>
          <Text style={{ position: 'absolute', top: 538, left: 321, fontSize: 12, width: 300, fontFamily:'Bold' }}>
             {calculateTotalMarks()}
          </Text>
          <Text style={{ position: 'absolute', top: 532, left: 467, fontSize: 12, width: 300, fontFamily:'Bold' }}>
             {calculateTotalObtainedMarks()}
          </Text>
          <Text style={{ position: 'absolute', top: 260, left: 170, fontSize: 10, width: 300 }}>
             {FormattedCalculateSessionDuration(session)}
          </Text>
          <Text style={{ position: 'absolute', top: 542, left: 386, fontSize: 12, width: 300, fontFamily:'Bold' }}>
            {calculatePercentage()}
          </Text>
          <Text style={{ position: 'absolute', top: 518, left: 110, fontSize: 10, width: 300 }}>
            {formattedIssuedDate}
          </Text>
          <Text style={{ position: 'absolute', top: 300, left: 60, fontSize: 11, width: 300, textDecoration: 'underline', fontFamily:'BoldItalic' }}>
          THEORY
          </Text>
           
          {showPractical && (
            <Text style={{ position: 'absolute', top: 426, left: 60, fontSize: 11, width: 300, textDecoration: 'underline', fontFamily:'BoldItalic' }}>
              PRACTICAL
            </Text>
          )}
          {showPractical1 && (
            <Text style={{ position: 'absolute', top: 394, left: 60, fontSize: 11, width: 300, textDecoration: 'underline', fontFamily:'BoldItalic' }}>
              PRACTICAL
            </Text>
          )}
        
{/* For Each CourseCode */}

  {(courseCode === 'DFSE') && (
  <>
    {part1Subjects.map((mark, index) => (
      <View key={index}>
        <View style1={{ position: 'absolute', top: 300 + (index * 0), left: 260, width: 200 }}>
          <Text style={{ fontSize: 9,top:315, left:60,padding:'3px', width: 250 }}>{mark.subject_name || 'N/A'}</Text>
        </View>
        <View style={{ position: 'absolute', top: 308 + (index * 0), left: 320, width: 200 }}>
          <Text style={{ fontSize: 10, fontWeight: 'bold', top:10 }}>{mark.total_marks || 'N/A'}</Text>
        </View>
        <View style={{ position: 'absolute', top: 308 + (index * 0), left: 390, width: 200 }}>
          <Text style={{ fontSize: 10, top:10 }}>{mark.minimum_marks || 'N/A'}</Text>
        </View>
        <View style={{ position: 'absolute', top: 308 + (index * 0), left: 460, width: 200 }}>
          <Text style={{ fontSize: 10, top:10 }}>{mark.marks_obtained || 'N/A'}</Text>
        </View>
      </View>
    ))}

    {part2Subjects.map((mark, index) => (
      <View key={index}>
        <View style1={{ position: 'absolute', top: 300 + (index * 0), left: 260, width: 200 }}>
          <Text style={{ fontSize: 9, top:330, left:60,padding:'3px', width: 250 }}>{mark.subject_name || 'N/A'}</Text>
        </View>
        <View style={{ position: 'absolute', top: 308 + (index * 0), left: 320, width: 200 }}>
          <Text style={{ fontSize: 10, top:20 }}>{mark.total_marks || 'N/A'}</Text>
        </View>
        <View style={{ position: 'absolute', top: 308 + (index * 0), left: 390, width: 200 }}>
          <Text style={{ fontSize: 10, top:20 }}>{mark.minimum_marks || 'N/A'}</Text>
        </View>
        <View style={{ position: 'absolute', top: 308 + (index * 0), left: 460, width: 200 }}>
          <Text style={{ fontSize: 10, top:20 }}>{mark.marks_obtained || 'N/A'}</Text>
        </View>
      </View>
    ))}
  </>
)}

{(courseCode === 'CFSE') && (
  <>
    {part1Subjects.map((mark, index) => (
      <View key={index}>
        <View style1={{ position: 'absolute', top: 300 + (index * 0), left: 260, width: 200 }}>
          <Text style={{ fontSize: 9,top:315, left:60,padding:'3px', width: 250 }}>{mark.subject_name || 'N/A'}</Text>
        </View>
        <View style={{ position: 'absolute', top: 308 + (index * 0), left: 320, width: 200 }}>
          <Text style={{ fontSize: 10, fontWeight: 'bold', top:10 }}>{mark.total_marks || 'N/A'}</Text>
        </View>
        <View style={{ position: 'absolute', top: 308 + (index * 0), left: 390, width: 200 }}>
          <Text style={{ fontSize: 10, top:10 }}>{mark.minimum_marks || 'N/A'}</Text>
        </View>
        <View style={{ position: 'absolute', top: 308 + (index * 0), left: 460, width: 200 }}>
          <Text style={{ fontSize: 10, top:10 }}>{mark.marks_obtained || 'N/A'}</Text>
        </View>
      </View>
    ))}

    {part2Subjects.map((mark, index) => (
      <View key={index}>
        <View style1={{ position: 'absolute', top: 300 + (index * 0), left: 260, width: 200 }}>
          <Text style={{ fontSize: 9, top:330, left:60,padding:'3px', width: 250 }}>{mark.subject_name || 'N/A'}</Text>
        </View>
        <View style={{ position: 'absolute', top: 308 + (index * 0), left: 320, width: 200 }}>
          <Text style={{ fontSize: 10, top:20 }}>{mark.total_marks || 'N/A'}</Text>
        </View>
        <View style={{ position: 'absolute', top: 308 + (index * 0), left: 390, width: 200 }}>
          <Text style={{ fontSize: 10, top:20 }}>{mark.minimum_marks || 'N/A'}</Text>
        </View>
        <View style={{ position: 'absolute', top: 308 + (index * 0), left: 460, width: 200 }}>
          <Text style={{ fontSize: 10, top:20 }}>{mark.marks_obtained || 'N/A'}</Text>
        </View>
      </View>
    ))}
  </>
)}

{courseCode === 'DIS' && searchResults.map((mark, index) => (
  <View key={index}>
    <View style1={{ position: 'absolute', top: 300 + (index * 0), left: 260, width: 230 }}>
      <Text style={{ fontSize: 10, top:315, left:60,padding:'4px', width: 240 }}>{mark.subject_name || 'N/A'}</Text>
    </View>
    <View style={{ position: 'absolute', top: 308 + (index * 0), left: 320, width: 200 }}>
      <Text style={{ fontSize: 10, top:9 }}>{mark.total_marks || 'N/A'}</Text>
    </View>
    <View style={{ position: 'absolute', top: 308 + (index * 0), left: 390, width: 200 }}>
      <Text style={{ fontSize: 10, top:9 }}>{mark.minimum_marks || 'N/A'}</Text>
    </View>
    <View style={{ position: 'absolute', top: 308 + (index * 0), left: 460, width: 200 }}>
      <Text style={{ fontSize: 10, top:9 }}>{mark.marks_obtained || 'N/A'}</Text>
    </View>
  </View>
))}

{courseCode === 'ADISE' && searchResults.map((mark, index) => (
  <View key={index}>
    <View style1={{ position: 'absolute', top: 300 + (index * 0), left: 260, width: 200 }}>
      <Text style={{ fontSize: 10, top:315, left:60,padding:'4px', width: 250 }}>{mark.subject_name || 'N/A'}</Text>
    </View>
    <View style={{ position: 'absolute', top: 308 + (index * 0), left: 320, width: 200 }}>
      <Text style={{ fontSize: 10, top:9 }}>{mark.total_marks || 'N/A'}</Text>
    </View>
    <View style={{ position: 'absolute', top: 308 + (index * 0), left: 390, width: 200 }}>
      <Text style={{ fontSize: 10, top:9 }}>{mark.minimum_marks || 'N/A'}</Text>
    </View>
    <View style={{ position: 'absolute', top: 308 + (index * 0), left: 460, width: 200 }}>
      <Text style={{ fontSize: 10, top:9 }}>{mark.marks_obtained || 'N/A'}</Text>
    </View>
  </View>
))}

{courseCode === 'ADFISE' && searchResults.map((mark, index) => (
  <View key={index}>
    <View style1={{ position: 'absolute', top: 300 + (index * 0), left: 260, width: 200 }}>
      <Text style={{ fontSize: 10, top:315, left:60,padding:'4px', width: 250 }}>{mark.subject_name || 'N/A'}</Text>
    </View>
    <View style={{ position: 'absolute', top: 308 + (index * 0), left: 320, width: 200 }}>
      <Text style={{ fontSize: 10, top:9 }}>{mark.total_marks || 'N/A'}</Text>
    </View>
    <View style={{ position: 'absolute', top: 308 + (index * 0), left: 390, width: 200 }}>
      <Text style={{ fontSize: 10, top:9 }}>{mark.minimum_marks || 'N/A'}</Text>
    </View>
    <View style={{ position: 'absolute', top: 308 + (index * 0), left: 460, width: 200 }}>
      <Text style={{ fontSize: 10, top:9 }}>{mark.marks_obtained || 'N/A'}</Text>
    </View>
  </View>
))}

{courseCode === 'DFISE' && searchResults.map((mark, index) => (
  <View key={index}>
    <View style1={{ position: 'absolute', top: 300 + (index * 0), left: 260, width: 200 }}>
      <Text style={{ fontSize: 10, top:315, left:60,padding:'4px', width: 250 }}>{mark.subject_name || 'N/A'}</Text>
    </View>
    <View style={{ position: 'absolute', top: 308 + (index * 0), left: 320, width: 200 }}>
      <Text style={{ fontSize: 10,top:9 }}>{mark.total_marks || 'N/A'}</Text>
    </View>
    <View style={{ position: 'absolute', top: 308 + (index * 0), left: 390, width: 200 }}>
      <Text style={{ fontSize: 10, top:9 }}>{mark.minimum_marks || 'N/A'}</Text>
    </View>
    <View style={{ position: 'absolute', top: 308 + (index * 0), left: 460, width: 200 }}>
      <Text style={{ fontSize: 10, top:9 }}>{mark.marks_obtained || 'N/A'}</Text>
    </View>
  </View>
))}

        </View>
      </Page>
    </Document>
  );

  return (
    <Box p={3}>
      <Typography variant="h4" align="center" style={{ fontSize: 36 }}>
        Marksheet
      </Typography>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              label="Enrollment Number"
              variant="outlined"
              size="small"
              value={enrollmentNumber}
              onChange={(e) => setEnrollmentNumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              label="Name"
              variant="outlined"
              size="small"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled
            />
          </Grid>
        </Grid>
        <Box mt={2} display="flex" justifyContent="center" alignItems="center" gap={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            aria-label="Search Certificate"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Search'}
          </Button>
          <Button
            variant="contained"
            color="info"
            onClick={handleGenerateCertificate}
            disabled={!searchCompleted}
          >
            Generate Certificate
          </Button>
          {certificateGenerated && showDownloadButton && (
            <PDFDownloadLink
              document={<MyDocument />}
              fileName="certificate.pdf"
              style={styles.downloadButton}
            >
              {({ loading }) => (loading ? 'Preparing document...' : 'Download Certificate')}
            </PDFDownloadLink>
          )}
        </Box>
        {error && (
          <Typography color="error" align="center" mt={2}>
            {error}
          </Typography>
        )}
        {searchResults.length > 0 && (
          <Box mt={4} p={2} border={1} borderColor="grey.300" borderRadius={2} bgcolor="background.paper">
            <Typography variant="h6" mb={2} color="textPrimary">
              Search Results
            </Typography>
            <Box style={styles.detailsContainer}>
              <Typography style={styles.detailsText}><strong>Name:</strong> {name}</Typography>
              <Typography style={styles.detailsText}><strong>Father's Name:</strong> {fathersName}</Typography>
              <Typography style={styles.detailsText}><strong>Session:</strong> {session}</Typography>
              <Typography style={styles.detailsText}><strong>Enrollment Number:</strong> {enrollmentNumber}</Typography>
              <Typography style={styles.detailsText}><strong>Course Code:</strong> {courseCode}</Typography>
              <Typography style={styles.detailsText}><strong>Date of Birth:</strong> {formatDate(dateOfBirth)}</Typography>
            </Box>
            
            <Typography variant="h6" mb={4} color="textPrimary" textAlign="center">
              Marks Table
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Subject Name</TableCell>
                    <TableCell>Total Marks</TableCell>
                    <TableCell>Minimum Marks</TableCell>
                    <TableCell>Marks Obtained</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchResults.map((mark, index) => (
                    <TableRow key={index}>
                      <TableCell>{mark.subject_name || 'N/A'}</TableCell>
                      <TableCell>{mark.total_marks || 'N/A'}</TableCell>
                      <TableCell>{mark.minimum_marks || 'N/A'}</TableCell>
                      <TableCell>{mark.marks_obtained || 'N/A'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
        {certificateGenerated && showDownloadButton && (
          <Box mt={4} p={2} border={1} borderColor="grey.300" borderRadius={2} bgcolor="background.paper">
            <Typography variant="h6" mb={2} color="textPrimary">
            Generated Marksheet:
            </Typography>
            <PDFViewer width="100%" height={600}>
              <MyDocument />
            </PDFViewer>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Marksheet;

